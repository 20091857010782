import { render, staticRenderFns } from "./AracHacziForm.vue?vue&type=template&id=08fad864&scoped=true"
import script from "./AracHacziForm.vue?vue&type=script&lang=ts"
export * from "./AracHacziForm.vue?vue&type=script&lang=ts"
import style0 from "./AracHacziForm.vue?vue&type=style&index=0&id=08fad864&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08fad864",
  null
  
)

export default component.exports