
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import IsverenList from "@/components/lists/haciz/IsverenList.vue";
import NoterPicker from "@/components/pickers/NoterPicker.vue";
import EnabledSwitch from "@/components/inputs/EnabledSwitch.vue";
import Dates from "@/components/inputs/Dates.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import HacizTalepMaasHacziCalismaDurumuPicker
  from "@/components/pickers/uyap/HacizTalepMaasHacziCalismaDurumuPicker.vue";
import DosyaAraciKisiKurumTarafListesiPicker from "@/components/pickers/uyap/DosyaAraciKisiKurumTarafListesiPicker.vue";
import {AvukatTalepListCevapRow} from "@/plugins/uyap-plugin/uyap/AvukatTalepList";

@Component({
  components: {
    DosyaAraciKisiKurumTarafListesiPicker,
    HacizTalepMaasHacziCalismaDurumuPicker,
    FormDialog,
    IsverenList,
    NoterPicker,
    EnabledSwitch,
    Dates,
    Tutar,
  }
})
export default class MaasHacziForm extends Mixins(ObjectInputMixin) {
  @Prop() dosyaId!: number;
  @Prop() avukatTalepList!: Array<AvukatTalepListCevapRow>;

  @Watch('localValue.ekstra_data.muzekkerenin_teblig_durumu')
  changeTebligDurumu(){
    if(!this.localValue.ekstra_data.muzekkerenin_teblig_durumu){
      this.localValue.ekstra_data.manual_teblig_turu = null
    }
  }

  mounted() {
    if ((!this.localValue.uyap_data || this.localValue.uyap_data.talepKodu != this.localValue.haciz_turu.talep_kodu) && this.$store.getters.isUyapConnected) {
      let talep = this.avukatTalepList.find(value => value.talepKodu == this.localValue.haciz_turu.talep_kodu);
      this.localValue.uyap_data = {...talep};
      this.localValue.uyap_data.sorguId = '';
      this.localValue.uyap_data.maasininBirBoluDordu = false;
      this.localValue.uyap_data.alacaklarininTamami = false;
      this.localValue.uyap_data.emekliIkramiyeHaczi = false;
      this.localValue.uyap_data.nafaka = false;
      this.input();
    }
  }
}
